import type {ElementorElement, ElementorElementWidget, ElementorContent} from "~/integration/wordpress/types";

export default () => {
    const getElementor = (content: ElementorContent) => {
        if (!content?.elementor) return false;
        const data = JSON.parse(content.elementor);
        if (!data || typeof data !== 'object') return false;
        return data;
    }

    const isExternalLink = (element: ElementorElement | ElementorElementWidget, name = 'link') => {
        return element?.settings?.[name]?.url?.indexOf('http') > -1;
    }

    const isLinkTargetBlank = (element: ElementorElement | ElementorElementWidget, name = 'link') => {
        return element?.settings?.[name]?.is_external === 'on'
    }

    const getSettingsValue = (element: ElementorElement | ElementorElementWidget, name: string, prop: undefined | string = undefined) => {
        if (prop) {
            return element?.settings?.[name]?.[prop]
        }
        return element?.settings?.[name];
    }

    const isThatDefault = (element: ElementorElement | ElementorElementWidget, name: string) => {
        return Boolean(getSettingsValue(element, name)) || getSettingsValue(element, name) === undefined;
    }

    return {
        getElementor,
        isExternalLink,
        isLinkTargetBlank,
        getSettingsValue,
        isThatDefault
    }
}